<template>
  <div>
    <div class="row logo valign-wrapper center-align">
      <div class="col s12">
        <svg width="300" height="150">
          <image
            xlink:href="/assets/logo.svg"
            src="/assets/logo.png"
            width="300"
            height="150"
          />
        </svg>
      </div>
    </div>
    <div class="row">
      <svg
        xml:space="preserve"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="vector-decoration"
        height="100px"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 90 20"
        width="100%"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
      >
        <path
          _ngcontent-xes-c8=""
          d="M0 0 V5 H5 C25 5 25 20 45 20 S65 5 85 5 H90 V0 Z"
        ></path>
      </svg>
    </div>
    <div class="container">
      <div class="row">
        <div class="col s12">
          <h2 class="center-align">😊 {{ $t("hello") }}</h2>
          <h4 class="center-align">{{ $t("scatta") }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col s6 right-align">
          <a
            href="https://apps.apple.com/dk/app/ilfotoalbum-stampa-fotolibri/id1490230334"
          >
            <svg width="310" height="150">
              <image
                xlink:href="/assets/appstore.svg"
                width="300"
                height="150"
              />
            </svg>
          </a>
        </div>
        <div class="col s6 left-align">
          <a
            href="https://play.google.com/store/apps/details?id=com.ilfotoalbum.app"
          >
            <svg width="330" height="150">
              <image
                xlink:href="/assets/googleplayb.svg"
                width="330"
                height="150"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col s12 center-align">
            <a href="https://www.ilfotoalbum.com/">{{ $t("visita")  }}</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>


<script>
import { isAndroid, isIOS } from "mobile-device-detect";
export default {
  name: "HelloWorld",
  mounted() {
    this.android = isAndroid;
    this.ios = isIOS;
    if (isAndroid) {
      try {
        setTimeout(function () {
          window.location =
            "https://play.google.com/store/apps/details?id=com.ilfotoalbum.app";
        }, 500);
        window.location = "ilfotoalbum://app.ilfotoalbum.com";
        console.log("passed");
      } catch (ex) {
        window.location =
          "https://play.google.com/store/apps/details?id=com.ilfotoalbum.app";
      }
    }
    if (isIOS) {
      try {
        setTimeout(function () {
          window.location =
            "https://apps.apple.com/dk/app/ilfotoalbum-stampa-fotolibri/id1490230334";
        }, 500);
        window.location = "ilfotoalbum://app.ilfotoalbum.com";
        console.log("passed");
      } catch (ex) {
        window.location =
          "https://apps.apple.com/dk/app/ilfotoalbum-stampa-fotolibri/id1490230334";
      }
    }
  },
  props: {
    msg: String,
  },
  data() {
    return {
      android: false,
      ios: false,
    };
  },
};
</script>


