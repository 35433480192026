import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'

Vue.config.productionTip = false

window.isMobile = require('mobile-device-detect');

new Vue({
    i18n,
    render: h => h(App)
}).$mount('#app')